define('ember-cpm/macros/fmt', ['exports', 'ember-string', 'ember-macro-helpers/computed'], function (exports, _emberString, _emberMacroHelpersComputed) {
  'use strict';

  /**
    Generates a string interpolating the given values.
  
    Example
  
    ```javascript
    var teacher = Ember.Object.extend({
      name: 'Miguel',
      course: 'Javacript',
      catchPhrase: fmt('name', 'course', 'Hi, my name is %@ and I will teach you %@')
    }).create();
  
  
    teacher.get('catchPhrase'); // 'Hi, my name is Miguel and I will teach you Javascript'
    ```
  
    @method fmt
    @for macros
    @param *arguments The last element is the string to format. The rest of the arguments are the dependent key with the values to interpolate.
                      The string interpolations follows the same rules in `Ember.String.fmt`
    @return The formatted string.
  */

  exports['default'] = function () {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var formatString = args.pop();

    return _emberMacroHelpersComputed['default'].apply(undefined, args.concat([function () {
      for (var _len2 = arguments.length, values = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        values[_key2] = arguments[_key2];
      }

      for (var i in values) {
        var value = values[i];
        if (value === undefined) {
          return undefined;
        }
        if (value === null) {
          return null;
        }
      }

      return (0, _emberString.fmt)(formatString, values);
    }]));
  };
});