define('ember-form-for/components/form-for', ['exports', 'ember', 'ember-form-for/templates/components/form-for'], function (exports, _ember, _emberFormForTemplatesComponentsFormFor) {
  'use strict';

  var Component = _ember['default'].Component;
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  var isPresent = _ember['default'].isPresent;
  var schedule = _ember['default'].run.schedule;
  var set = _ember['default'].set;

  var FormForComponent = Component.extend({
    layout: _emberFormForTemplatesComponentsFormFor['default'],

    tagName: 'form',

    config: service('ember-form-for/config'),

    attributeBindings: ['tabindex', 'form:id'],

    init: function init() {
      this._super.apply(this, arguments);

      var formClasses = get(this, 'config.formClasses');
      var classNames = get(this, 'classNames');
      set(this, 'classNames', (classNames || []).concat(formClasses));

      this.propertyDidChange();
    },

    submit: function submit(object) {
      return object.save();
    },
    reset: function reset(object) {
      return object.rollback();
    },

    update: function update(object, propertyName, value) {
      set(object, propertyName, value);
    },

    handleErrors: function handleErrors(object) {
      var _this = this;

      var errors = get(object, 'errors');

      if (errors) {
        for (var propertyName in errors) {
          if (isPresent(get(errors, propertyName))) {
            set(this, 'tabindex', -1);
            schedule('afterRender', function () {
              if (_this.isDestroyed || _this.isDestroying) {
                return;
              }
              _this.$().focus();
            });
            break;
          }
        }
      }
    },

    actions: {
      submit: function submit(object) {
        var _this2 = this;

        var promise = get(this, 'submit')(object);

        set(this, 'tabindex', undefined);

        if (promise && typeof promise['finally'] === 'function') {
          promise['finally'](function () {
            return _this2.handleErrors(object);
          });
        } else {
          this.handleErrors(object);
        }

        return promise;
      }
    }
  });

  FormForComponent.reopenClass({
    positionalParams: ['object']
  });

  exports['default'] = FormForComponent;
});