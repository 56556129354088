define('ember-burger-menu/components/burger-menu', ['exports', 'ember', 'ember-burger-menu/templates/components/burger-menu', 'ember-burger-menu/computed/style-for', 'ember-burger-menu/mixins/swipe-support'], function (exports, _ember, _emberBurgerMenuTemplatesComponentsBurgerMenu, _emberBurgerMenuComputedStyleFor, _emberBurgerMenuMixinsSwipeSupport) {
  'use strict';

  var $ = _ember['default'].$;
  var on = _ember['default'].on;
  var run = _ember['default'].run;
  var observer = _ember['default'].observer;
  var computed = _ember['default'].computed;
  var alias = _ember['default'].computed.alias;
  var service = _ember['default'].inject.service;

  exports['default'] = _ember['default'].Component.extend(_emberBurgerMenuMixinsSwipeSupport['default'], {
    classNames: ['ember-burger-menu'],
    classNameBindings: ['open:is-open', 'translucentOverlay', 'animationClass', 'position'],
    attributeBindings: ['style'],
    layout: _emberBurgerMenuTemplatesComponentsBurgerMenu['default'],

    state: service('burgerMenu'),

    translucentOverlay: true,
    dismissOnClick: true,
    dismissOnEsc: true,

    open: alias('state.open'),
    position: alias('state.position'),
    width: alias('state.width'),
    animation: alias('state.animation'),
    itemAnimation: alias('state.itemAnimation'),
    customAnimation: alias('state.customAnimation'),

    style: (0, _emberBurgerMenuComputedStyleFor['default'])('container').readOnly(),

    animationClass: computed('state.styles.animation', function () {
      return 'bm--' + this.get('state.styles.animation');
    }).readOnly(),

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      run.cancel(this._setupEventsTimer);
      this._teardownEvents();
    },

    setupEvents: on('didInsertElement', observer('open', function () {
      var methodName = this.get('open') ? '_setupEvents' : '_teardownEvents';
      this._setupEventsTimer = run.scheduleOnce('afterRender', this, methodName);
    })),

    _setupEvents: function _setupEvents() {
      var $element = this.$();
      var onClick = this.onClick.bind(this);
      var onKeyUp = this.onKeyup.bind(this);

      if (this.get('dismissOnClick')) {
        $element.on('click.bm', onClick);
        $element.on('touchstart.bm', onClick);
      }

      if (this.get('dismissOnEsc')) {
        $(document).on('keyup.bm', onKeyUp);
      }
    },

    _teardownEvents: function _teardownEvents() {
      var $element = this.$();

      $element.off('click.bm');
      $element.off('touchstart.bm');
      $(document).off('keyup.bm');
    },

    onClick: function onClick(e) {
      // Close the menu if clicked outside of it
      if ($(e.target).closest('.bm-menu').length === 0) {
        e.stopPropagation();
        e.preventDefault();

        this.set('open', false);
      }
    },

    onKeyup: function onKeyup(e) {
      if (e.keyCode === 27) {
        this.set('open', false);
      }
    },

    onSwipe: function onSwipe(direction, target) {
      var position = this.get('position');
      var open = this.get('open');
      var isMenuSwipe = $(target).closest('.bm-menu').length > 0;

      if (open && isMenuSwipe && position === direction) {
        this.set('open', false);
      } else if (!open && position !== direction) {
        this.set('open', true);
      }
    }
  });
});