define('ember-form-for/components/form-fields/checkbox-group', ['exports', 'ember', 'ember-form-for/templates/components/form-fields/checkbox-group', 'ember-invoke-action'], function (exports, _ember, _emberFormForTemplatesComponentsFormFieldsCheckboxGroup, _emberInvokeAction) {
  'use strict';

  var Component = _ember['default'].Component;
  var get = _ember['default'].get;

  var CheckboxGroupComponent = Component.extend({
    tagName: '',
    layout: _emberFormForTemplatesComponentsFormFieldsCheckboxGroup['default'],

    actions: {
      updateSelection: function updateSelection(value, object, propertyName, include) {
        var selection = get(object, propertyName);
        if (include && !selection.contains(value)) {
          selection.pushObject(value);
        } else {
          selection.removeObject(value);
        }

        (0, _emberInvokeAction.invokeAction)(this, 'update', object, propertyName, selection);
      }
    }
  });

  CheckboxGroupComponent.reopenClass({
    positionalParams: ['propertyName']
  });

  exports['default'] = CheckboxGroupComponent;
});