define('ember-form-for/components/form-fields/checkbox-field', ['exports', 'ember', 'ember-form-for/templates/components/form-fields/checkbox-field'], function (exports, _ember, _emberFormForTemplatesComponentsFormFieldsCheckboxField) {
  'use strict';

  var Component = _ember['default'].Component;
  var set = _ember['default'].set;

  var CheckboxFieldComponent = Component.extend({
    tagName: '',
    layout: _emberFormForTemplatesComponentsFormFieldsCheckboxField['default'],

    control: 'one-way-checkbox',

    update: function update(object, propertyName, value) {
      set(object, propertyName, value);
    }
  });

  CheckboxFieldComponent.reopenClass({
    positionalParams: ['propertyName']
  });

  exports['default'] = CheckboxFieldComponent;
});