define('ember-form-for/components/form-fields/radio-group', ['exports', 'ember', 'ember-form-for/templates/components/form-fields/radio-group'], function (exports, _ember, _emberFormForTemplatesComponentsFormFieldsRadioGroup) {
  'use strict';

  var Component = _ember['default'].Component;
  var set = _ember['default'].set;

  var RadioGroupComponent = Component.extend({
    tagName: '',
    layout: _emberFormForTemplatesComponentsFormFieldsRadioGroup['default'],

    update: function update() {
      set.apply(undefined, arguments);
    }
  });

  RadioGroupComponent.reopenClass({
    positionalParams: ['propertyName']
  });

  exports['default'] = RadioGroupComponent;
});