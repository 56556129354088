define('ember-burger-menu/animations/base', ['exports', 'ember', 'ember-burger-menu/utils/css-stringify'], function (exports, _ember, _emberBurgerMenuUtilsCssStringify) {
  'use strict';

  var assert = _ember['default'].assert;

  var assign = _ember['default'].assign || _ember['default'].merge;

  var Animation = _ember['default'].Object.extend({
    animation: null,
    itemAnimation: null,

    container: function container() /* open, width, right */{
      return {};
    },

    outlet: function outlet() /* open, width, right */{
      return {};
    },

    menu: function menu() /* open, width, right */{
      return {};
    },

    menuItem: function menuItem() /* open, width, right, index */{
      return {};
    },

    generateCSSFor: function generateCSSFor(type, _ref) {
      var open = _ref.open;
      var width = _ref.width;
      var position = _ref.position;
      var index = _ref.index;

      var result = undefined;

      assert('Width must be a number.', typeof width === 'number');
      assert('Position must be either \'left\' or \'right\'.', position === 'left' || position === 'right');

      if (type === 'menuItem' && index === -1) {
        /*
          If the index is -1 that means the specific menu item hasnt been
          rendered yet or it isn't found.
         */
        result = {};
      } else {
        result = this[type](open, width, position === 'right', index);
      }

      if (type === 'menu') {
        assign(result, { width: width + 'px' });
      }

      return (0, _emberBurgerMenuUtilsCssStringify['default'])(result);
    }
  });

  Animation.reopenClass({
    __isAnimation__: true
  });

  exports['default'] = Animation;
});