define('ember-burger-menu/components/bm-menu-item', ['exports', 'ember', 'ember-burger-menu/templates/components/bm-menu-item', 'ember-burger-menu/computed/style-for'], function (exports, _ember, _emberBurgerMenuTemplatesComponentsBmMenuItem, _emberBurgerMenuComputedStyleFor) {
  'use strict';

  var $ = _ember['default'].$;
  var run = _ember['default'].run;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberBurgerMenuTemplatesComponentsBmMenuItem['default'],
    classNames: ['bm-menu-item'],
    attributeBindings: ['style'],

    state: service('burgerMenu'),

    menuItems: null,
    dismissOnClick: false,
    style: (0, _emberBurgerMenuComputedStyleFor['default'])('menuItem').readOnly(),

    index: computed('menuItems.[]', function () {
      var $item = this.$();
      return $item ? $('.bm-menu-item', $item.closest('.bm-menu')).index($item) : -1;
    }).readOnly(),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      run.scheduleOnce('afterRender', this.get('menuItems'), 'addObject', this.get('elementId'));
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      run.scheduleOnce('afterRender', this.get('menuItems'), 'removeObject', this.get('elementId'));
    },

    click: function click() {
      if (this.get('dismissOnClick')) {
        this.set('state.open', false);
      }
    }
  });
});