define('flexi-layouts/services/device/layout', ['exports', 'ember', 'flexi-layouts/utils/capitalize', 'flexi-layouts/lib/monitor'], function (exports, _ember, _flexiLayoutsUtilsCapitalize, _flexiLayoutsLibMonitor) {
  'use strict';

  var computed = _ember['default'].computed;
  var copy = _ember['default'].copy;
  var Service = _ember['default'].Service;
  var run = _ember['default'].run;

  exports['default'] = Service.extend({

    width: 1000,
    height: 500,

    monitor: _flexiLayoutsLibMonitor['default'],

    orientation: computed('width', 'height', function () {
      var resolution = this.getProperties('width', 'height');
      var isLandscape = resolution.width >= resolution.height;

      return isLandscape ? 'landscape' : 'portrait';
    }).readOnly(),

    // TODO: deprecate deviceIs in favor of orientationIs
    deviceIsLandscape: computed.equal('orientation', 'landscape'),
    deviceIsPortrait: computed.not('deviceIsLandscape'),

    orientationIsLandscape: computed.alias('deviceIsLandscape'),
    orientationIsPortrait: computed.alias('deviceIsPortrait'),

    breakpoints: null,
    _resizeHandler: null,

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      if (typeof window === 'object' && typeof document === 'object') {
        window.removeEventListener('resize', this._resizeHandler, true);
      }
    },

    updateResolution: function updateResolution() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

      this.setProperties({
        width: w,
        height: h
      });
    },

    setupResize: function setupResize() {
      var _this = this;

      this._resizeHandler = function () {
        run.debounce(_this, _this.updateResolution, 16);
      };
      window.addEventListener('resize', this._resizeHandler, true);
    },

    setupBreakpoints: function setupBreakpoints() {
      var _this2 = this;

      if (!this.breakpoints) {
        throw new Error('You must configure some breakpoints');
      }

      // sort breakpoints largest to smallest
      this.breakpoints = this.breakpoints.sort(function (a, b) {
        return a.begin > b.begin ? -1 : 1;
      });

      // sort smallest to largest
      var bps = copy(this.breakpoints, true).sort(function (a, b) {
        return a.begin > b.begin ? 1 : -1;
      });

      bps.forEach(function (bp, i) {

        _ember['default'].defineProperty(_this2, 'is' + (0, _flexiLayoutsUtilsCapitalize['default'])(bp.name), computed('width', function () {
          var width = this.get('width');
          var next = bps[i + 1];

          if (next) {
            return width >= bp.begin && width < next.begin;
          }
          return width >= bp.begin;
        }));

        _ember['default'].defineProperty(_this2, 'isAtLeast' + (0, _flexiLayoutsUtilsCapitalize['default'])(bp.name), computed('width', function () {
          var width = this.get('width');

          return width >= bp.begin;
        }));
      });
    },

    init: function init() {
      this._super();

      this.setupBreakpoints();

      if (typeof window === 'object' && typeof document === 'object') {
        this.setupResize();
        this.updateResolution();
      }
    }

  });
});