define('ember-burger-menu/utils/css-stringify', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = cssStringify;

  var isEmpty = _ember['default'].isEmpty;
  var htmlSafe = _ember['default'].String.htmlSafe;

  var PREFIXES = ['webkit'];
  var PREFIXED_PROPS = ['transition', 'transition-timing-function', 'transition-property', 'transition-speed', 'transition-delay', 'transform', 'transform-style', 'transform-origin', 'perspective', 'perspective-origin'];
  function cssStringify() {
    var hash = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

    return htmlSafe(Object.keys(hash).reduce(function (css, key) {
      var value = hash[key];

      if (!isEmpty(value)) {
        css = css.concat(buildProp(key, value));
      }

      return css;
    }, []).join('; '));
  }

  function buildProp(key, value) {
    var css = [key + ': ' + value];

    if (PREFIXED_PROPS.indexOf(key) > -1) {
      PREFIXES.forEach(function (p) {
        css.push('-' + p + '-' + key + ': ' + value);
      });
    }

    return css;
  }
});