define('ember-form-for/components/form-fields/textarea-field', ['exports', 'ember', 'ember-form-for/templates/components/form-fields/textarea-field'], function (exports, _ember, _emberFormForTemplatesComponentsFormFieldsTextareaField) {
  'use strict';

  var Component = _ember['default'].Component;
  var set = _ember['default'].set;

  var TextareaFieldComponent = Component.extend({
    tagName: '',
    layout: _emberFormForTemplatesComponentsFormFieldsTextareaField['default'],

    control: 'one-way-textarea',

    update: function update(object, propertyName, value) {
      set(object, propertyName, value);
    }
  });

  TextareaFieldComponent.reopenClass({
    positionalParams: ['propertyName']
  });

  exports['default'] = TextareaFieldComponent;
});