define('ember-cpm/macros/among', ['exports', 'ember-cpm/utils'], function (exports, _emberCpmUtils) {
  'use strict';

  /**
    Returns true the given value in is among the supplied options.
  
    Example
  
    ```javascript
    var Show = Ember.Object.extend({
      hasCartoonDog: among('pet.name', 'Odie', 'Snoopy')
    });
  
    var show = Show.create({ pet: { name: 'Garfield' } });
  
    show.get('hasCartoonDog'); // false
    show.set('pet.name', 'Snoopy');
    show.get('hasCartoonDog'); // true
    ```
    @method among
    @for macros
    @param {String|Number|ComputedProperty} dependentKey Dependent key which value must be among the given values.
    @param                                  *values      Values among which the dependentKey must be included.
    @return {Boolean} Returns true the value in the given dependent key is among the privided values.
  */
  exports['default'] = (0, _emberCpmUtils.resolveKeysUnsafe)(function (source) {
    for (var _len = arguments.length, values = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      values[_key - 1] = arguments[_key];
    }

    for (var i = 0; i < values.length; i++) {
      if (values[i] === source) {
        return true;
      }
    }
    return false;
  });
});