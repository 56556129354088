define('ember-cpm/utils', ['exports', 'ember', 'ember-macro-helpers/computed', 'ember-macro-helpers/computed-unsafe'], function (exports, _ember, _emberMacroHelpersComputed, _emberMacroHelpersComputedUnsafe) {
  'use strict';

  var _slice = Array.prototype.slice;
  exports.resolveKeys = resolveKeys;
  exports.resolveKeysUnsafe = resolveKeysUnsafe;
  exports.reduceKeysUnsafe = reduceKeysUnsafe;
  exports.parseComputedPropertyMacro = parseComputedPropertyMacro;

  /**
    EmberCPM Utils
  
    @module utils
    @requires ember
  */

  /**
   Builds a computed property macro
  
   @method resolveKeysUnsafe
   @for utils
   @param {Function} callback passed resolved values to calculate the property
   */

  function resolveKeys(callback) {
    return function () {
      return _emberMacroHelpersComputed['default'].apply(undefined, _slice.call(arguments).concat([callback]));
    };
  }

  /**
   Builds a computed property macro (unsafe version)
  
   @method resolveKeysUnsafe
   @for utils
   @param {Function} callback passed resolved values to calculate the property
   */

  function resolveKeysUnsafe(callback) {
    return function () {
      return _emberMacroHelpersComputedUnsafe['default'].apply(undefined, _slice.call(arguments).concat([callback]));
    };
  }

  /**
   Builds a computed property macro based on array reducing
  
   @method reduceKeysUnsafe
   @for utils
   @param {Function} callback passed resolved values to calculate the property
   */

  function reduceKeysUnsafe(callback) {
    return resolveKeysUnsafe(function () {
      for (var _len = arguments.length, values = Array(_len), _key = 0; _key < _len; _key++) {
        values[_key] = arguments[_key];
      }

      values = values.reduce(function (values, valueOrArray) {
        return values.concat(valueOrArray);
      }, []);
      if (values.length === 0) {
        return 0;
      }
      return values.reduce(callback);
    });
  }

  /**
   Generate a "parse-like" computed property macro
  
   Example:
   ```js
   parseComputedPropertyMacro(function (raw) {return parseFloat(raw);});
   ```
  
   @method parseComputedPropertyMacro
   @param {function} parseFunction single-argument function that transforms a raw value into a "parsed" value
   */

  function parseComputedPropertyMacro(parseFunction) {
    return function (dependantKey) {
      if ('undefined' === typeof dependantKey) {
        throw new TypeError('No argument');
      }
      if (dependantKey === null) {
        throw new TypeError('Null argument');
      }

      return (0, _emberMacroHelpersComputedUnsafe['default'])(dependantKey, {
        get: function get(rawValue) {
          // Check for null/undefined values
          if (_ember['default'].A(['undefined', 'null']).indexOf(_ember['default'].typeOf(rawValue)) !== -1) {
            return NaN;
          } else {
            // Handle some unexpected behavior for empty-string property keys
            // related:
            //  https://github.com/emberjs/ember.js/commit/b7e82f43c3475ee7b166a2570b061f08c6c6c0f3#diff-22c6caff03531b3e718e9a8d82180833R31
            if ('string' === typeof rawValue && rawValue.length === 0) {
              return NaN;
            } else {
              return parseFunction(rawValue);
            }
          }
        },
        set: function set(val, rawValue) {
          //setter
          //respect the type of the dependent property
          switch (_ember['default'].typeOf(rawValue)) {
            case 'number':
              this.set(dependantKey, parseFloat(val));
              break;
            case 'boolean':
              switch (_ember['default'].typeOf(val)) {
                case 'string':
                  this.set(dependantKey, val.toLowerCase() === 'true');
                  break;
                case 'number':
                  this.set(dependantKey, val !== 0);
                  break;
                default:
                  var msg = _ember['default'].String.fmt('Can\'t transform value of type %@ into a boolean', _ember['default'].typeOf(val));
                  throw new TypeError(msg);
              }
              break;
            default:
              this.set(dependantKey, val.toString());
              break;
          }
          return val;
        }
      });
    };
  }
});