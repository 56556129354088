define('ember-cpm/macros/hash', ['exports', 'ember-macro-helpers/computed'], function (exports, _emberMacroHelpersComputed) {
  'use strict';

  /**
    Returns a hash of its arguments
  
    Example
  
    ```javascript
    var obj = Ember.Object.extend({
      a: 6,
      b: 7,
      thing: hash('a', 'b') // {a: 6, b: 7}
    });
    ```
  
    @method hash
    @for macros
    @param *arguments Property keys to include
    @return {Object} Mapping of key names to values
  */

  exports['default'] = function () {
    for (var _len = arguments.length, props = Array(_len), _key = 0; _key < _len; _key++) {
      props[_key] = arguments[_key];
    }

    return _emberMacroHelpersComputed['default'].apply(undefined, props.concat([function () {
      for (var _len2 = arguments.length, values = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        values[_key2] = arguments[_key2];
      }

      var result = {};
      props.forEach(function (prop, i) {
        result[prop] = values[i];
      });
      return result;
    }]));
  };
});