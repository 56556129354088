define('@html-next/vertical-collection/-debug/edge-visualization/debug-mixin', ['exports', '@html-next/vertical-collection/-debug/edge-visualization/visualization', '@html-next/vertical-collection/-private', '@html-next/vertical-collection/-debug/utils/validate-style'], function (exports, _htmlNextVerticalCollectionDebugEdgeVisualizationVisualization, _htmlNextVerticalCollectionPrivate, _htmlNextVerticalCollectionDebugUtilsValidateStyle) {
  'use strict';

  exports['default'] = Ember.Mixin.create({
    debugVis: false,
    debugCSS: false,

    __visualization: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this._radar._debugDidUpdate = function () {
        _this.updateVisualization();
        _this.detectIssuesWithCSS();
      };
    },
    detectIssuesWithCSS: function detectIssuesWithCSS() {
      if (this.get('debugCSS') === false) {
        return;
      }

      var radar = this._radar;
      var styles = void 0;

      // check telescope
      if (radar.scrollContainer !== _htmlNextVerticalCollectionPrivate.ViewportContainer) {
        styles = window.getComputedStyle(radar.scrollContainer);
      } else {
        styles = window.getComputedStyle(document.body);
      }

      true && !(0, _htmlNextVerticalCollectionDebugUtilsValidateStyle.styleIsOneOf)(styles, 'display', ['block', 'inline-block', 'flex', 'inline-flex']) && Ember.assert('scrollContainer cannot be inline.', (0, _htmlNextVerticalCollectionDebugUtilsValidateStyle.styleIsOneOf)(styles, 'display', ['block', 'inline-block', 'flex', 'inline-flex']));
      true && !(0, _htmlNextVerticalCollectionDebugUtilsValidateStyle.styleIsOneOf)(styles, 'position', ['static', 'relative', 'absolute']) && Ember.assert('scrollContainer must define position', (0, _htmlNextVerticalCollectionDebugUtilsValidateStyle.styleIsOneOf)(styles, 'position', ['static', 'relative', 'absolute']));
      true && !((0, _htmlNextVerticalCollectionDebugUtilsValidateStyle.hasStyleWithNonZeroValue)(styles, 'height') || (0, _htmlNextVerticalCollectionDebugUtilsValidateStyle.hasStyleWithNonZeroValue)(styles, 'max-height')) && Ember.assert('scrollContainer must define height or max-height', (0, _htmlNextVerticalCollectionDebugUtilsValidateStyle.hasStyleWithNonZeroValue)(styles, 'height') || (0, _htmlNextVerticalCollectionDebugUtilsValidateStyle.hasStyleWithNonZeroValue)(styles, 'max-height'));

      // conditional perf check for non-body scrolling

      if (radar.scrollContainer !== _htmlNextVerticalCollectionPrivate.ViewportContainer) {
        true && !((0, _htmlNextVerticalCollectionDebugUtilsValidateStyle.hasStyleValue)(styles, 'overflow-y', 'scroll') || (0, _htmlNextVerticalCollectionDebugUtilsValidateStyle.hasStyleValue)(styles, 'overflow', 'scroll')) && Ember.assert('scrollContainer must define overflow-y', (0, _htmlNextVerticalCollectionDebugUtilsValidateStyle.hasStyleValue)(styles, 'overflow-y', 'scroll') || (0, _htmlNextVerticalCollectionDebugUtilsValidateStyle.hasStyleValue)(styles, 'overflow', 'scroll'));
      }

      // check itemContainer
      styles = window.getComputedStyle(radar.itemContainer);

      true && !(0, _htmlNextVerticalCollectionDebugUtilsValidateStyle.styleIsOneOf)(styles, 'display', ['block', 'inline-block', 'flex', 'inline-flex']) && Ember.assert('itemContainer cannot be inline.', (0, _htmlNextVerticalCollectionDebugUtilsValidateStyle.styleIsOneOf)(styles, 'display', ['block', 'inline-block', 'flex', 'inline-flex']));
      true && !(0, _htmlNextVerticalCollectionDebugUtilsValidateStyle.styleIsOneOf)(styles, 'position', ['static', 'relative', 'absolute']) && Ember.assert('itemContainer must define position', (0, _htmlNextVerticalCollectionDebugUtilsValidateStyle.styleIsOneOf)(styles, 'position', ['static', 'relative', 'absolute']));

      // check item defaults

      true && !this.get('items.length') && Ember.assert('You must supply at least one item to the collection to debug it\'s CSS.', this.get('items.length'));

      var element = radar._itemContainer.firstElementChild;

      styles = window.getComputedStyle(element);

      true && !(0, _htmlNextVerticalCollectionDebugUtilsValidateStyle.styleIsOneOf)(styles, 'display', ['block', 'inline-block', 'flex', 'inline-flex']) && Ember.assert('Item cannot be inline.', (0, _htmlNextVerticalCollectionDebugUtilsValidateStyle.styleIsOneOf)(styles, 'display', ['block', 'inline-block', 'flex', 'inline-flex']));
      true && !(0, _htmlNextVerticalCollectionDebugUtilsValidateStyle.styleIsOneOf)(styles, 'position', ['static', 'relative', 'absolute']) && Ember.assert('Item must define position', (0, _htmlNextVerticalCollectionDebugUtilsValidateStyle.styleIsOneOf)(styles, 'position', ['static', 'relative', 'absolute']));
    },
    updateVisualization: function updateVisualization() {
      if (this.get('debugVis') === false) {
        if (this.__visualization !== null) {
          console.info('tearing down existing visualization'); // eslint-disable-line no-console
          this.__visualization.destroy();
          this.__visualization = null;
        }
        return;
      }

      if (this.__visualization === null) {
        this.__visualization = new _htmlNextVerticalCollectionDebugEdgeVisualizationVisualization['default'](this._radar);
      }

      this.__visualization.render();
    },
    willDestroy: function willDestroy() {
      this._super();
      if (this.__visualization) {
        console.info('destroying visualization'); // eslint-disable-line no-console
        this.__visualization.destroy();
        this.__visualization = null;
      }
    }
  });
});