define('ember-cpm/macros/mean', ['exports', 'ember-utils', 'ember-cpm/utils'], function (exports, _emberUtils, _emberCpmUtils) {
  'use strict';

  /**
    Calculate the arithmetic mean of some numeric properties, numeric literals,
    and/or arrays of numeric properties and literals.
  
    If any of its elements is an array it calculates the mean with its elements.
  
    Example
  
    ```javascript
    var obj = Ember.Object.extend({
      lowestPrice: 8,
      highestPrice: 10,
      ages: [10, 20, 30],
      avgPrice: mean('lowestPrice', 'highestPrice'), // 9
      avgAge: mean('ages'),                          // 20
      avgCustom: mean(4, 10)                         // 7
    });
    ```
  
    @method mean
    @for macros
    @param *arguments It can be a number, an array of numbers, a property key pointing to any of those, or another computed property.
    @return {Number}  The arithmetical mean of the given values.
   */
  exports['default'] = (0, _emberCpmUtils.resolveKeysUnsafe)(function () {
    for (var _len = arguments.length, values = Array(_len), _key = 0; _key < _len; _key++) {
      values[_key] = arguments[_key];
    }

    var sum = 0;
    var count = 0;

    values.forEach(function (v) {
      var t = (0, _emberUtils.typeOf)(v);
      switch (t) {
        case 'number':
          // Number case
          count += 1;
          sum += v;
          break;
        case 'array':
          // Array case
          sum += v.reduce(function (p, i) {
            return p + i;
          }, 0); // sum of array
          count += v.length;
          break;
        case 'undefined':
        case 'null':
          break;
        default:
          var msg = 'Unsupported value type: ' + t;
          throw new TypeError(msg);
      }
    });
    return count > 0 ? sum / count : 0;
  });
});