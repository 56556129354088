define('ember-browser-checker/helpers/is-chrome', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Helper.extend({
    browserChecker: _ember['default'].inject.service(),

    compute: function compute() {
      var browserChecker = this.get('browserChecker');
      return browserChecker.get('isChrome');
    }
  });
});