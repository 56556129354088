define('ember-cpm/macros/join', ['exports', 'ember-macro-helpers/computed'], function (exports, _emberMacroHelpersComputed) {
  'use strict';

  /**
    Joins the strings in the given property keys.
  
    Example
  
    ```javascript
    var picard = Ember.Object.extend({
      firstName: 'Jean-Luc',
      lastName:  'Picard',
      fullName:  join('firstName', 'lastName', ' ')
    }).create();
  
    picard.get('fullName'); // 'Jean-Luc Picard'
    ```
  
    @method join
    @for macros
    @param *arguments The last argument is the separator string.
      The rest are the dependent keys with the strings to join, string literals or other
      computed property macros
    @return {String}  The joined string.
  */

  exports['default'] = function () {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var separator = args.pop();

    return _emberMacroHelpersComputed['default'].apply(undefined, args.concat([function () {
      for (var _len2 = arguments.length, values = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        values[_key2] = arguments[_key2];
      }

      return values.join(separator);
    }]));
  };
});