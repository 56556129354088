define('ember-burger-menu/components/bm-outlet', ['exports', 'ember', 'ember-burger-menu/templates/components/bm-outlet', 'ember-burger-menu/computed/style-for'], function (exports, _ember, _emberBurgerMenuTemplatesComponentsBmOutlet, _emberBurgerMenuComputedStyleFor) {
  'use strict';

  var service = _ember['default'].inject.service;

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberBurgerMenuTemplatesComponentsBmOutlet['default'],
    classNames: ['bm-outlet'],
    attributeBindings: ['style'],
    state: service('burgerMenu'),
    style: (0, _emberBurgerMenuComputedStyleFor['default'])('outlet').readOnly()
  });
});