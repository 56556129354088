define('ember-form-for/components/fields-for', ['exports', 'ember', 'ember-form-for/templates/components/fields-for'], function (exports, _ember, _emberFormForTemplatesComponentsFieldsFor) {
  'use strict';

  var Component = _ember['default'].Component;
  var set = _ember['default'].set;

  var FieldsForComponent = Component.extend({
    layout: _emberFormForTemplatesComponentsFieldsFor['default'],

    tagName: '',

    update: function update(object, propertyName, value) {
      set(object, propertyName, value);
    }
  });

  FieldsForComponent.reopenClass({
    positionalParams: ['object']
  });

  exports['default'] = FieldsForComponent;
});