define('ember-burger-menu/mixins/swipe-support', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var isNone = _ember['default'].isNone;

  var meta = undefined;

  exports['default'] = _ember['default'].Mixin.create({
    minSwipeDistance: 150,
    maxSwipeTime: 300,

    onSwipe: function onSwipe() /* direction, target */{},

    touchStart: function touchStart(e) {
      this._super.apply(this, arguments);

      // jscs:disable
      var touch = e.originalEvent.touches[0];
      // jscs:enable

      meta = {
        target: e.target,
        start: {
          x: touch.pageX,
          y: touch.pageY,
          time: new Date().getTime()
        }
      };
    },

    touchMove: function touchMove(e) {
      this._super.apply(this, arguments);

      // jscs:disable
      var touch = e.originalEvent.touches[0];
      // jscs:enable

      meta.differences = {
        x: touch.pageX - meta.start.x,
        y: touch.pageY - meta.start.y
      };

      // Compute swipe direction
      if (isNone(meta.isHorizontal)) {
        meta.isHorizontal = Math.abs(meta.differences.x) > Math.abs(meta.differences.y);
      }

      // A valid swipe event uses only one finger
      if (e.originalEvent.touches.length > 1) {
        meta.isInvalid = true;
      }

      if (meta.isHorizontal && !meta.isInvalid) {
        e.preventDefault();
      }
    },

    touchEnd: function touchEnd() {
      this._super.apply(this, arguments);

      var minSwipeDistance = this.get('minSwipeDistance');
      var maxSwipeTime = this.get('maxSwipeTime');
      var elapsedTime = new Date().getTime() - meta.start.time;

      if (meta.isHorizontal && !meta.isInvalid && Math.abs(meta.differences.x) >= minSwipeDistance && elapsedTime <= maxSwipeTime) {
        this.onSwipe(meta.differences.x > 0 ? 'right' : 'left', meta.target);
      }
    }
  });
});