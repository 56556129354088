define('ember-links-with-follower/configuration', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var getWithDefault = _ember['default'].getWithDefault;
  var testing = _ember['default'].testing;
  var typeOf = _ember['default'].typeOf;

  var DEFAULTS = {
    followerAnimationDuration: testing ? 0 : 150
  };

  exports['default'] = {
    followerAnimationDuration: DEFAULTS.followerAnimationDuration,

    load: function load(config) {
      for (var property in this) {
        if (this._hasProperty(property)) {
          this[property] = getWithDefault(config, property, DEFAULTS[property]);
        }
      }
    },

    _hasProperty: function _hasProperty(prop) {
      return this.hasOwnProperty(prop) && typeOf(this[prop]) !== 'function';
    }
  };
});