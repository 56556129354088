define('@html-next/vertical-collection/-debug/utils/validate-rect', ['exports', '@html-next/vertical-collection/-debug/utils/validate-style'], function (exports, _htmlNextVerticalCollectionDebugUtilsValidateStyle) {
  'use strict';

  exports.hasDimension = hasDimension;
  exports.hasDimensionAbove = hasDimensionAbove;
  exports.hasDimensionEqual = hasDimensionEqual;

  function hasDimension(rect, prop) {
    return (0, _htmlNextVerticalCollectionDebugUtilsValidateStyle.isNonZero)(rect[prop]);
  }

  function hasDimensionAbove(rect, prop, amount) {
    return hasDimension(rect, prop) && rect[prop] >= amount;
  }

  function hasDimensionEqual(rect, prop, amount) {
    return hasDimension(rect, prop) && rect[prop] === amount;
  }
});