define('ember-links-with-follower/components/links-with-follower', ['exports', 'ember', 'ember-links-with-follower/templates/components/links-with-follower', 'ember-runloop', 'ember-utils', 'ember-metal/utils', 'ember-array/utils', 'ember-metal/events', 'ember-computed', 'jquery', 'ember-links-with-follower/configuration'], function (exports, _ember, _emberLinksWithFollowerTemplatesComponentsLinksWithFollower, _emberRunloop, _emberUtils, _emberMetalUtils, _emberArrayUtils, _emberMetalEvents, _emberComputed, _jquery, _emberLinksWithFollowerConfiguration) {
  'use strict';

  var getOwner = _ember['default'].getOwner;

  /**
   * A component that renders a follower line underneath provided "links".
   * Expects a block to be passed that contains a set of links to render.
   *
   * ```hbs
   * \{{#links-with-follower class="my-nav"}}
   *   {{#link-to 'route1' tagName='li'}}Route 1{{/link-to}}
   *   {{#link-to 'route2' tagName='li'}}Route 2{{/link-to}}
   * {{/links-with-follower}}
   * ```
   *
   * @class LinksWithFollower
   * @module Components
   */
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberLinksWithFollowerTemplatesComponentsLinksWithFollower['default'],
    tagName: 'nav',
    classNames: ['links-with-follower'],

    /**
     * Tag name to use for the element wrapping the provided links.
     *
     * @property containerTagName
     * @type {String}
     * @default 'ul'
     */
    containerTagName: 'ul',

    /**
     * Tag name to use for the follower. It is expected to match items passed
     * in via the block.
     *
     * @property linkTagName
     * @type {String}
     * @default 'li'
     */
    linkTagName: 'li',

    /**
     * The selector used to consider a link active.
     *
     * @property activeSelector
     * @type {String}
     * @default 'li.active'
     */
    activeSelector: 'li.active:not(li.ember-transitioning-out), li.ember-transitioning-in',

    /**
     * The duration used to animate the follower link.
     *
     * @property followerAnimationDuration
     * @type {Number}
     * @default 150
     */
    followerAnimationDuration: (0, _emberComputed['default'])({
      get: function get() {
        return _emberLinksWithFollowerConfiguration['default'].followerAnimationDuration;
      }
    }),

    /**
     * Where to position the follower. Not yet used.
     *
     * @property followerPosition
     * @type {String}
     * @default 'bottom'
     */
    followerPosition: 'bottom', // TODO: support top, bottom, both

    init: function init() {
      this._super.apply(this, arguments);
      this.router = getOwner(this).lookup('router:main');

      (0, _emberMetalEvents.addListener)(this.router, 'willTransition', this, this._queueMoveFollower);
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this._assertChildrenMatchSelector();
      this._ensureCorrectInitialPosition();

      this._onResizeHandler = function () {
        (0, _emberRunloop.debounce)(_this, _this._moveFollower, false, 20);
      };
      this._installResizeListener();
    },

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      (0, _emberMetalEvents.removeListener)(this.router, 'willTransition', this, this._queueMoveFollower);
      (0, _emberRunloop.cancel)(this.nextRun);
      this.router = null;

      this._uninstallResizeListener();
    },

    /**
     * Ensures the position of the follower is correct.
     *
     * @private
     */
    _ensureCorrectInitialPosition: function _ensureCorrectInitialPosition() {
      this._ensureCorrectPositionOnNextRun();
      this._ensureCorrectPositionOnWindowLoad();
    },

    /**
     * Ensures the position of the follower on the next run.
     *
     * @private
     */
    _ensureCorrectPositionOnNextRun: function _ensureCorrectPositionOnNextRun() {
      this.nextRun = (0, _emberRunloop.next)(this, this._moveFollower, false);
    },

    /**
     * Ensures the initial position of the follower is correct, even if font or
     * image assets are slow to load.
     *
     * @private
     */
    _ensureCorrectPositionOnWindowLoad: function _ensureCorrectPositionOnWindowLoad() {
      var _this2 = this;

      window.onload = function () {
        return _this2._moveFollower(false);
      };
    },

    /**
     * Adds event listener to update the follower after a browser resize
     *
     * @private
     */
    _installResizeListener: function _installResizeListener() {
      window.addEventListener('resize', this._onResizeHandler);
    },

    /**
     * Removes event listener to update the follower after a browser resize
     *
     * @private
     */
    _uninstallResizeListener: function _uninstallResizeListener() {
      window.removeEventListener('resize', this._onResizeHandler);
    },

    /**
     * Asserts that children passed in via block match the provided childSelector
     * property.
     *
     * @private
     */
    _assertChildrenMatchSelector: function _assertChildrenMatchSelector() {
      var containerTagName = this.get('containerTagName');
      var linkTagName = this.get('linkTagName');
      var children = (0, _emberArrayUtils.A)(this.$('' + containerTagName).children().toArray());
      var childrenMatch = children.every(function (c) {
        return (0, _jquery['default'])(c).is(linkTagName);
      });

      (0, _emberMetalUtils.assert)('children in block must match the \'linkTagName\' property', childrenMatch);
    },

    /**
     * Queues moving of a follower.
     * @private
     */
    _queueMoveFollower: function _queueMoveFollower() {
      (0, _emberRunloop.scheduleOnce)('afterRender', this, this._moveFollower);
    },

    /**
     * Moves and resizes the link follower to match the currently active link.
     *
     * @param  {Boolean} animate=true If the follower should animate
     * @private
     */
    _moveFollower: function _moveFollower() {
      var animate = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      var activeLink = this._activeLink();

      if (activeLink.length === 0) {
        this._hideFollower();
      }

      var width = this._widthOfActiveLink();
      var left = this._leftPositionOfActiveLink();
      left = left + this._marginLeftOfActiveLink();
      var shouldHideFollower = (0, _emberUtils.isEmpty)(width) || (0, _emberUtils.isEmpty)(left);

      this._hideFollower(shouldHideFollower);
      this._animateFollower(animate, { width: width, left: left });
    },

    /**
     * Shows or hides the follower link.
     *
     * @param  {Boolean} shouldHideFollower=true If the follower should animate
     * @private
     */
    _hideFollower: function _hideFollower() {
      var shouldHideFollower = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

      var follower = this.$('.link-follower');

      if (shouldHideFollower) {
        follower.hide();
      } else {
        follower.show();
      }
    },

    /**
     * Animates a follower to its final width / position.
     *
     * @param  {Boolean} animate=true If the follower should animate or not
     * @param  {Object} options={} The animated properties, left and width, passed to jQuery's css method
     * @private
     */
    _animateFollower: function _animateFollower() {
      var animate = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];
      var options = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      var follower = this.$('.link-follower');
      var duration = animate ? this.get('followerAnimationDuration') : 0;
      var left = options.left;
      var width = options.width;

      var css = {
        transform: 'translate3d(' + left + 'px, 0px, 0px)',
        transitionDuration: duration + 'ms',
        width: width
      };

      follower.css(css);
    },

    /**
     * Gets the width of the active link.
     *
     * @return {Number} The width including margins/padding
     * @private
     */
    _widthOfActiveLink: function _widthOfActiveLink() {
      var activeLink = this._activeLink();

      return activeLink.outerWidth();
    },

    /**
     * Gets the left position of the active link.
     *
     * @return {Number} The left position or 0 if it cannot be determined
     * @private
     */
    _leftPositionOfActiveLink: function _leftPositionOfActiveLink() {
      var activeLink = this._activeLink();
      var offset = activeLink.position() || { left: 0 };

      return offset.left;
    },

    _marginLeftOfActiveLink: function _marginLeftOfActiveLink() {
      var activeLink = this._activeLink();
      var marginLeft = activeLink.css('marginLeft');

      return parseInt(marginLeft);
    },

    /**
     * Gets the active link based on `activeSelector`.
     *
     * @return {Object} The results of the jQuery selector
     * @private
     */
    _activeLink: function _activeLink() {
      var activeSelector = this.get('activeSelector');
      return this.$(activeSelector);
    }
  });
});