define('ember-cpm/macros/if-null', ['exports', 'ember-macro-helpers/computed'], function (exports, _emberMacroHelpersComputed) {
  'use strict';

  var _slice = Array.prototype.slice;

  /**
    Returns the value in the given dependent key, or if is null, the provided default value.
  
    Example
  
    ```javascript
    var obj = Ember.Object.extend({
      username: ifNull('name', 'Anonymous')
    }).create();
  
    obj.get('username'); // 'Anonymous'
    ```
  
    @method ifNull
    @for macros
    @param {String} dependentKey Name of the key with the possible null value.
    @param {Number|String|ComputedProperty} fallback Default value that the CP will return if the dependent key is null.
    @return
  */

  exports['default'] = function () {
    var _arguments = arguments;

    return _emberMacroHelpersComputed['default'].apply(undefined, _slice.call(arguments).concat([function (value, fallback) {
      return value != null ? value : fallback || _arguments[1];
    }]));
  };
});