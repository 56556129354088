define('ember-form-for/components/form-fields/radio-field', ['exports', 'ember', 'ember-form-for/templates/components/form-fields/radio-field', 'ember-form-for/utils/strings'], function (exports, _ember, _emberFormForTemplatesComponentsFormFieldsRadioField, _emberFormForUtilsStrings) {
  'use strict';

  var Component = _ember['default'].Component;
  var dasherize = _ember['default'].String.dasherize;
  var computed = _ember['default'].computed;
  var or = _ember['default'].computed.or;
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  var isPresent = _ember['default'].isPresent;
  var set = _ember['default'].set;

  var RadioFieldComponent = Component.extend({
    tagName: '',
    layout: _emberFormForTemplatesComponentsFormFieldsRadioField['default'],

    control: 'one-way-radio',

    i18n: service(),
    config: service('ember-form-for/config'),

    modelName: or('object.modelName', 'object.constructor.modelName'),

    update: function update(object, propertyName, value) {
      set(object, propertyName, value);
    },

    labelText: computed('value', 'label', function () {
      var i18n = get(this, 'i18n');
      var label = get(this, 'label');

      if (isPresent(label)) {
        return label;
      } else if (isPresent(i18n)) {
        return i18n.t(get(this, 'labelI18nKey'));
      } else {
        return get(this, 'label') || (0, _emberFormForUtilsStrings.humanize)(get(this, 'value'));
      }
    }),

    labelI18nKey: computed('config.i18nKeyPrefix', 'modelName', 'propertyName', 'value', function () {
      var value = get(this, 'value');

      if (isPresent(value)) {
        value = dasherize(value.toString());
      }

      return [get(this, 'config.i18nKeyPrefix'), dasherize(get(this, 'modelName') || ''), dasherize(get(this, 'propertyName') || ''), value].filter(function (x) {
        return !!x;
      }).join('.');
    })
  });

  RadioFieldComponent.reopenClass({
    positionalParams: ['propertyName', 'value']
  });

  exports['default'] = RadioFieldComponent;
});