define('ember-form-for/utils/date', ['exports'], function (exports) {
  'use strict';

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i['return']) _i['return']();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError('Invalid attempt to destructure non-iterable instance');
      }
    };
  })();

  var abs = Math.abs;
  var ceil = Math.ceil;
  var pad = function pad(number) {
    var amount = arguments.length <= 1 || arguments[1] === undefined ? 2 : arguments[1];

    var padding = new Array(amount).join('0');
    return ('' + padding + number).slice(-amount);
  };

  exports.pad = pad;

  var toMonthString = function toMonthString(date) {
    return date.getFullYear() + '-' + pad(date.getMonth() + 1);
  };

  exports.toMonthString = toMonthString;

  var toWeekString = function toWeekString(date) {
    date = new Date(+date);
    date.setHours(0, 0, 0);
    date.setDate(date.getDate() + 4 - (date.getDay() || 7));

    var weekNumber = ceil(((date - new Date(date.getFullYear(), 0, 1)) / 8.64e7 + 1) / 7);

    return date.getFullYear() + '-W' + weekNumber;
  };

  exports.toWeekString = toWeekString;

  var fromWeekString = function fromWeekString(string) {
    var _string$match$slice = string.match(/^(\d{4})-W(\d{1,2})$/).slice(1);

    var _string$match$slice2 = _slicedToArray(_string$match$slice, 2);

    var year = _string$match$slice2[0];
    var weekNr = _string$match$slice2[1];

    var jan1OfYear = new Date(Number(year), 0, 1);
    var jan4OfYear = new Date(Number(year), 0, 4);
    var correction = (jan4OfYear.getDay() || 7) + 4;

    var offsetFrom1Jan = (Number(weekNr) * 7 + 1 - correction) * 8.64e7;
    var result = new Date(+jan1OfYear + offsetFrom1Jan);

    result.setHours(0, 0, 0);

    return result;
  };

  exports.fromWeekString = fromWeekString;

  var toDateString = function toDateString(date) {
    return toMonthString(date) + '-' + pad(date.getDate());
  };

  exports.toDateString = toDateString;

  var toTimeString = function toTimeString(date) {
    return pad(date.getHours()) + ':' + pad(date.getMinutes());
  };

  exports.toTimeString = toTimeString;

  var toDatetimeLocalString = function toDatetimeLocalString(date) {
    return toDateString(date) + 'T' + toTimeString(date);
  };

  exports.toDatetimeLocalString = toDatetimeLocalString;

  var formatTimeZoneOffset = function formatTimeZoneOffset(offset) {
    if (offset === 0) {
      offset = 'Z';
    } else {
      var hours = abs(offset) / 60;
      var minutes = abs(offset) % 60;
      var sign = offset > 0 ? '-' : '+';
      offset = '' + sign + pad(hours) + ':' + pad(minutes);
    }

    return offset;
  };
  exports.formatTimeZoneOffset = formatTimeZoneOffset;
});