define('ember-form-for/components/form-hint', ['exports', 'ember', 'ember-form-for/templates/components/form-hint'], function (exports, _ember, _emberFormForTemplatesComponentsFormHint) {
  'use strict';

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;

  exports['default'] = Component.extend({
    tagName: '',
    layout: _emberFormForTemplatesComponentsFormHint['default'],

    joinedHintClasses: computed('hintClasses', function () {
      return (get(this, 'hintClasses') || []).join(' ');
    })
  });
});