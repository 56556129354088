define('ember-links-with-follower/components/html-tag', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  /**
   * A component used to dynamically render html tags.
   *
   * ```hbs
   * \{{html-tag tagName="ul"}}
   * ```
   *
   * @class HtmlTag
   * @module Components
   */
  exports['default'] = _ember['default'].Component.extend({});
});