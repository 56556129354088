define("ember-flatpickr/components/ember-flatpickr", ["exports", "ember-diff-attrs"], function (exports, _emberDiffAttrs) {
  "use strict";

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  })(); /* eslint-disable ember/closure-actions, ember/no-attrs-in-components, ember/no-on-calls-in-components */

  exports["default"] = Ember.Component.extend({
    tagName: 'input',
    type: 'text',
    attributeBindings: ['placeholder', 'type'],
    date: null,
    flatpickrRef: null,

    setupComponent: Ember.on('init', function () {
      // Require that users pass a date
      true && !(this.get('date') !== undefined) && Ember.assert('{{ember-flatpickr}} requires a `date` to be passed as the value for flatpickr.', this.get('date') !== undefined);

      // Require that users pass an onChange

      true && !(this.get('onChange') !== undefined) && Ember.assert('{{ember-flatpickr}} requires an `onChange` action or null for no action.', this.get('onChange') !== undefined);

      // Pass all values and setup flatpickr

      Ember.run.scheduleOnce('afterRender', this, function () {
        var fastboot = Ember.getOwner(this).lookup('service:fastboot');
        if (fastboot && fastboot.isFastBoot) {
          return;
        }
        var options = this.getProperties(Object.keys(this.attrs));

        // Add defaultDate, change and close handlers
        Ember.assign(options, {
          defaultDate: this.get('date'),
          onChange: this._onChange.bind(this),
          onClose: this._onClose.bind(this),
          onOpen: this._onOpen.bind(this),
          onReady: this._onReady.bind(this)
        });

        var flatpickrRef = flatpickr(this.element, options);

        if (this.get('appendDataInput')) {
          this.element.setAttribute('data-input', '');
        }

        this._setDisabled(this.get('disabled'));

        this.set('flatpickrRef', flatpickrRef);
      });
    }),

    didReceiveAttrs: (0, _emberDiffAttrs["default"])('date', 'disabled', 'locale', 'maxDate', 'minDate', function (changedAttrs) {
      var _this = this;

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, args);

      this._attributeHasChanged(changedAttrs, 'date', function (newDate) {
        if (typeof newDate !== 'undefined') {
          _this.element._flatpickr.setDate(newDate);
        }
      });

      this._attributeHasChanged(changedAttrs, 'disabled', function (newDisabled) {
        if (typeof newDisabled !== 'undefined') {
          _this._setDisabled(newDisabled);
        }
      });

      this._attributeHasChanged(changedAttrs, 'locale', function () {
        _this.element._flatpickr.destroy();
        _this.setupComponent();
      });

      this._attributeHasChanged(changedAttrs, 'maxDate', function (newMaxDate) {
        _this.element._flatpickr.set('maxDate', newMaxDate);
      });

      this._attributeHasChanged(changedAttrs, 'minDate', function (newMinDate) {
        _this.element._flatpickr.set('minDate', newMinDate);
      });
    }),

    willDestroyElement: function willDestroyElement() {
      this.element._flatpickr.destroy();
    },

    /**
     * Check if the attr has changed, and if so call the callback with the new value
     * @param {object} changedAttrs The object with keys denoting attrs that have changed
     * @param {string} attr The string of which attr to check for changes
     * @param {function} callback A function to call with the newAttr value
     * @private
     */
    _attributeHasChanged: function _attributeHasChanged(changedAttrs, attr, callback) {
      if (changedAttrs && changedAttrs[attr]) {
        var _changedAttrs$attr = _slicedToArray(changedAttrs[attr], 2),
            oldAttr = _changedAttrs$attr[0],
            newAttr = _changedAttrs$attr[1];

        if (oldAttr !== newAttr) {
          callback(newAttr);
        }
      }
    },

    /**
     * When the date is changed, update the value and send 'onChange' action
     * @param selectedDates The array of selected dates
     * @param dateStr The string representation of the date, formatted by dateFormat
     * @param instance The flatpickr instance
     * @private
     */
    _onChange: function _onChange(selectedDates, dateStr, instance) {
      this.sendAction('onChange', selectedDates, dateStr, instance);
    },

    /**
     * When the flatpickr is closed, fire the 'onClose' action
     * @param selectedDates The array of selected dates
     * @param dateStr The string representation of the date, formatted by dateFormat
     * @param instance The flatpickr instance
     * @private
     */
    _onClose: function _onClose(selectedDates, dateStr, instance) {
      this.sendAction('onClose', selectedDates, dateStr, instance);
    },

    /**
     * When the flatpickr is opened, fire the 'onOpen' action
     * @param selectedDates The array of selected dates
     * @param dateStr The string representation of the date, formatted by dateFormat
     * @param instance The flatpickr instance
     * @private
     */
    _onOpen: function _onOpen(selectedDates, dateStr, instance) {
      this.sendAction('onOpen', selectedDates, dateStr, instance);
    },

    /**
     * When the flatpickr is ready, fire the 'onReady' action
     * @param selectedDates The array of selected dates
     * @param dateStr The string representation of the date, formatted by dateFormat
     * @param instance The flatpickr instance
     * @private
     */
    _onReady: function _onReady(selectedDates, dateStr, instance) {
      this.sendAction('onReady', selectedDates, dateStr, instance);
    },

    /**
     * Set disabled for the correct input, handling altInput weirdness
     * @param {boolean} disabled Disabled or not
     * @private
     */
    _setDisabled: function _setDisabled(disabled) {
      if (this.get('altInput')) {
        this.element.nextSibling.disabled = disabled;
      } else {
        this.element.disabled = disabled;
      }
    }
  });
});