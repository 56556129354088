define('ember-burger-menu/components/bm-menu', ['exports', 'ember', 'ember-burger-menu/templates/components/bm-menu', 'ember-burger-menu/computed/style-for'], function (exports, _ember, _emberBurgerMenuTemplatesComponentsBmMenu, _emberBurgerMenuComputedStyleFor) {
  'use strict';

  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var emberArray = _ember['default'].A;
  var service = _ember['default'].inject.service;
  var OUTLET_MENU_ANIMATIONS = ['push', 'rotate-out'];

  exports.OUTLET_MENU_ANIMATIONS = OUTLET_MENU_ANIMATIONS;
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberBurgerMenuTemplatesComponentsBmMenu['default'],
    state: service('burgerMenu'),

    itemTagName: 'div',
    dismissOnItemClick: false,

    style: (0, _emberBurgerMenuComputedStyleFor['default'])('menu').readOnly(),

    renderInPlace: computed('state.animation', function () {
      return OUTLET_MENU_ANIMATIONS.indexOf(this.get('state.animation')) === -1;
    }).readOnly(),

    menuItems: computed(function () {
      return emberArray([]);
    }).readOnly(),

    onOpenChange: observer('state.open', function () {
      this.sendAction(this.get('state.open') ? 'onOpen' : 'onClose');
    })
  });
});