define('ember-burger-menu/animations/scale-rotate', ['exports', 'ember-burger-menu/animations/base'], function (exports, _emberBurgerMenuAnimationsBase) {
  'use strict';

  exports['default'] = _emberBurgerMenuAnimationsBase['default'].extend({
    animation: 'scale-rotate',

    outlet: function outlet(open, width, right) {
      return {
        transform: open ? right ? 'translate3d(-100px, 0, -' + width * 2 + 'px) rotateY(20deg)' : 'translate3d(100px, 0, -' + width * 2 + 'px) rotateY(-20deg)' : ''
      };
    }
  });
});