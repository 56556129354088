define('ember-cpm/macros/concat', ['exports', 'ember-macro-helpers/computed', 'ember-macro-helpers/normalize-array-key'], function (exports, _emberMacroHelpersComputed, _emberMacroHelpersNormalizeArrayKey) {
  'use strict';

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
    } else {
      return Array.from(arr);
    }
  }

  /**
    Keeps n arrays concatenated.
  
    Example:
    ```js
    obj = Ember.Object.createWithMixins({
      itemsA: [],
      itemsB: [],
      itemsC: [],
      allItems: EmberCPM.Macros.concat('itemsA', 'itemsB', 'itemsC');
    });
  
    obj.get('itemsA').pushObjects(['a', 'b']);
    obj.get('allItems') //=> ['a', 'b']
  
    obj.get('itemsB').pushObjects(['c']);
    obj.get('allItems') //=> ['a', 'b', 'c']
  
    obj.get('itemsC').pushObjects(['d']);
    obj.get('allItems') //=> ['a', 'b', 'c', 'd']
  
    obj.get('itemsB').pushObjects(['e', 'f']);
    obj.get('allItems') //=> ['a', 'b', 'c', 'e', 'f', 'd']
    ```
  
    @method concat
    @for macros
    @param *arguments Dependent keys with the arrays to concat.
    @return {Array}
  */

  exports['default'] = function () {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var arrayReduce = function arrayReduce() {
      for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      return args.reduce(function (prev, value) {
        return prev.concat(value || []);
      }, []);
    };

    return _emberMacroHelpersComputed['default'].apply(undefined, _toConsumableArray(args.map(_emberMacroHelpersNormalizeArrayKey['default'])).concat([arrayReduce]));
  };
});