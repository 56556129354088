define("ember-cli-bugsnag/utils/bugsnag", ["exports"], function (exports) {
  "use strict";

  exports.getMetaData = getMetaData;
  exports.getUser = getUser;

  function getMetaData() /* error, container */{
    return {};
  }

  function getUser() /* container */{
    return {};
  }
});