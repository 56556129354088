define('ember-cpm/macros/computed-promise', ['exports', 'ember', 'rsvp', 'ember-macro-helpers/computed-unsafe'], function (exports, _ember, _rsvp, _emberMacroHelpersComputedUnsafe) {
  'use strict';

  var ObjectProxy = _ember['default'].ObjectProxy;
  var PromiseProxyMixin = _ember['default'].PromiseProxyMixin;
  var Promise = _rsvp['default'].Promise;

  var PromiseProxy = ObjectProxy.extend(PromiseProxyMixin);

  /**
    Updates computed property when supplied callback (which must return a
    promise) is resolved.
  
    Example
  
    ```javascript
    data: promiseObject('dep', function(){
      return ajax('/data.json');
    })
  
    myObject.get('data') // => undefined
  
    // once resolved
  
    myObject.get('data') // => { foo: 'bar' };
    ```
  
    For example, if you have a template that renders `data` when the promise is
    resolved the template will be updated.
  
    @method computedPromise
    @for macros
    @param *dependentKeys
    @param callback
    @returns obj result of callback
  */

  exports['default'] = function () {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var fn = args.pop();

    if (typeof fn !== 'function') {
      throw new Error('You must supply a function as the last argument to this macro.');
    }

    return _emberMacroHelpersComputedUnsafe['default'].apply(undefined, args.concat([function () {
      var promise = fn.call(this);
      if (!(promise instanceof Promise)) {
        return promise;
      }

      return PromiseProxy.create({
        promise: promise
      });
    }]));
  };
});