define('ember-cpm/macros/first-present', ['exports', 'ember-utils', 'ember-cpm/utils'], function (exports, _emberUtils, _emberCpmUtils) {
  'use strict';

  /**
    Returns the first argument that is not blank (as in Ember.isBlank)
  
    Example
  
    ```javascript
    var obj = Ember.Object.extend({
      nickname: '',
      name: 'Jean-Luc',
      email: 'jean@starship-enterprise.space',
      displayName: firstPresent('nickname', 'name', 'email')
    }).create();
  
  
    item.get('displayName'); // 'Jean-Luc'
    ```
  
    @method firstPresent
    @for macros
    @param *arguments
    @return The first arguments that is not blank.
  */
  exports['default'] = (0, _emberCpmUtils.resolveKeys)(function () {
    for (var _len = arguments.length, values = Array(_len), _key = 0; _key < _len; _key++) {
      values[_key] = arguments[_key];
    }

    for (var i in values) {
      var value = values[i];
      if (!(0, _emberUtils.isBlank)(value)) {
        return value;
      }
    }
  });
});