define('ember-cpm/index', ['exports', 'ember', 'ember-cpm/macros/among', 'ember-cpm/macros/all-equal', 'ember-cpm/macros/encode-uri-component', 'ember-cpm/macros/encode-uri', 'ember-cpm/macros/first-present', 'ember-cpm/macros/fmt', 'ember-cpm/macros/html-escape', 'ember-cpm/macros/if-null', 'ember-cpm/macros/promise', 'ember-cpm/macros/safe-string', 'ember-cpm/macros/join', 'ember-cpm/macros/sum', 'ember-cpm/macros/concat', 'ember-cpm/macros/conditional', 'ember-cpm/macros/product', 'ember-cpm/macros/quotient', 'ember-cpm/macros/difference', 'ember-cpm/macros/not', 'ember-cpm/macros/as-float', 'ember-cpm/macros/as-int', 'ember-cpm/macros/computed-promise'], function (exports, _ember, _emberCpmMacrosAmong, _emberCpmMacrosAllEqual, _emberCpmMacrosEncodeUriComponent, _emberCpmMacrosEncodeUri, _emberCpmMacrosFirstPresent, _emberCpmMacrosFmt, _emberCpmMacrosHtmlEscape, _emberCpmMacrosIfNull, _emberCpmMacrosPromise, _emberCpmMacrosSafeString, _emberCpmMacrosJoin, _emberCpmMacrosSum, _emberCpmMacrosConcat, _emberCpmMacrosConditional, _emberCpmMacrosProduct, _emberCpmMacrosQuotient, _emberCpmMacrosDifference, _emberCpmMacrosNot, _emberCpmMacrosAsFloat, _emberCpmMacrosAsInt, _emberCpmMacrosComputedPromise) {
  'use strict';

  var VERSION = '1.3.1';
  var Macros = {
    not: _emberCpmMacrosNot['default'],
    among: _emberCpmMacrosAmong['default'],
    allEqual: _emberCpmMacrosAllEqual['default'],
    encodeURIComponent: _emberCpmMacrosEncodeUriComponent['default'],
    encodeURI: _emberCpmMacrosEncodeUri['default'],
    firstPresent: _emberCpmMacrosFirstPresent['default'],
    fmt: _emberCpmMacrosFmt['default'],
    htmlEscape: _emberCpmMacrosHtmlEscape['default'],
    ifNull: _emberCpmMacrosIfNull['default'],
    promise: _emberCpmMacrosPromise['default'],
    safeString: _emberCpmMacrosSafeString['default'],
    join: _emberCpmMacrosJoin['default'],
    sum: _emberCpmMacrosSum['default'],
    difference: _emberCpmMacrosDifference['default'],
    concat: _emberCpmMacrosConcat['default'],
    conditional: _emberCpmMacrosConditional['default'],
    asFloat: _emberCpmMacrosAsFloat['default'],
    asInt: _emberCpmMacrosAsInt['default'],
    quotient: _emberCpmMacrosQuotient['default'],
    product: _emberCpmMacrosProduct['default'],
    computedPromise: _emberCpmMacrosComputedPromise['default']
  };

  if (_ember['default'].libraries) {
    _ember['default'].libraries.register('Ember-CPM', VERSION);
  }

  exports.VERSION = VERSION;
  exports.Macros = Macros;
  exports['default'] = {
    VERSION: VERSION,
    Macros: Macros
  };
});