define('ember-form-for/utils/strings', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var _Ember$String = _ember['default'].String;
  var capitalize = _Ember$String.capitalize;
  var decamelize = _Ember$String.decamelize;

  var WORD_SEPERATORS = new RegExp('[-_\. ]', 'g');

  var humanize = function humanize(string) {
    return capitalize(decamelize(string.toString()).replace(WORD_SEPERATORS, ' '));
  };

  exports.humanize = humanize;

  var titlecase = function titlecase(string) {
    return decamelize(string).split(WORD_SEPERATORS).map(function (w) {
      return capitalize(w);
    }).join(' ');
  };
  exports.titlecase = titlecase;
});