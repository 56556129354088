define('ember-burger-menu/animations/reveal', ['exports', 'ember-burger-menu/animations/base'], function (exports, _emberBurgerMenuAnimationsBase) {
  'use strict';

  exports['default'] = _emberBurgerMenuAnimationsBase['default'].extend({
    animation: 'reveal',

    outlet: function outlet(open, width, right) {
      return {
        transform: open ? right ? 'translate3d(-' + width + 'px, 0, 0)' : 'translate3d(' + width + 'px, 0, 0)' : ''
      };
    }
  });
});